import React from 'react'

export default function TabButton({setTab , tab}) {
    return (
        <div className='d-flex p-2 mb-3 mt-3'>

            <button onClick={() => setTab(1)} class={`btn btn-tab-bar ${tab === 1 ? 'active' : '' } mx-1`} type="button">Stake</button>
            <button onClick={() => setTab(2)} class={`btn btn-tab-bar ${tab === 2 ? 'active' : '' } mx-1`} type="button">Unstake</button>
            <button onClick={() => setTab(3)} class={`btn btn-tab-bar ${tab === 3 ? 'active' : '' } mx-1`} type="button">History</button>
        </div>
    )
}