import React, { useState } from 'react'
import { DEFAULT_CHAIN, contract } from '../helper/constant';
import { daysPassed, formatPrice, getWeb3, shorterDate } from '../helper/functions';
import stakeAbi from '../json/bscstake.json';
import { useAccount, useNetwork } from 'wagmi';
import { getContract } from '../helper/contractHelper';
import { toast } from 'react-toastify';
import { useEthersSigner } from '../hooks/useEthersProvider';
import TabButton from './TabButton';
import MyWallet from './MyWallet';
import { useStakeStats } from '../hooks/useBscAccount';
import { ClipLoader } from 'react-spinners';


export default function Unstake({ accStats, setUpdater, updater, setTab, tab, commonStats }) {
    const { address, isConnected } = useAccount()
    const { chain } = useNetwork()
    const [tLoading, setTLoading] = useState(['', false]);
    const signer = useEthersSigner()
    const [page, setPage] = useState(1)
    const stakeStats = useStakeStats(updater, page)



    const override = {
        display: "block",
        marginRight: "auto",
        marginLeft: "auto"
    };

    const handleTerminate = async (index) => {
        setTLoading([index, true]);
        try {

            if (address && isConnected) {
                if (chain.id === DEFAULT_CHAIN) {
                    let tokenStakingAddress = contract[DEFAULT_CHAIN].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);

                    let tx = await stakeContract.withdraw(index, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success.');
                                setUpdater(Math.random());
                                setTLoading(['', false]);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setTLoading(['', false]);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setTLoading(['', false]);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Binance Mainnet Network !');
                    setTLoading(['', false]);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setTLoading(['', false]);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setTLoading(['', false]);
        }
    }

    const handleClaim = async (index) => {
        setTLoading([index, true]);
        try {

            if (address && isConnected) {
                if (chain.id === DEFAULT_CHAIN) {
                    let tokenStakingAddress = contract[DEFAULT_CHAIN].STAKE_ADDRESS;
                    let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);

                    let tx = await stakeContract.claim(index, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success.');
                                setUpdater(Math.random());
                                setTLoading(['', false]);
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setUpdater(Math.random());
                                setTLoading(['', false]);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setUpdater(Math.random());
                                setTLoading(['', false]);
                            }
                        }
                    }, 5000);
                }
                else {
                    toast.error('Please select Binance Mainnet Network !');
                    setTLoading(['', false]);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setTLoading(['', false]);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setTLoading(['', false]);
        }
    }

    return (
        <div className='d-flex justify-content-center mb-4 row' style={{ marginLeft: 0 }}>
            <div className='col-12 col-xl-7 card'>
                <TabButton setTab={setTab} tab={tab} />
                <div className='table-responsive'>
                    <table class="table table-bordered border-primary table-unstake">
                        <thead>
                            <tr>
                                <th scope="col">Plan($)</th>
                                <th scope="col">APY</th>
                                <th scope="col">Start</th>
                                <th scope="col">Earn(%)</th>
                                <th scope="col">Time(D)</th>
                                <th scope="col">Earn(USDT)</th>
                                <th scope="col">Amount</th>
                                <th scope="col">USDT</th>
                                <th scope="col">WCCoin</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stakeStats.loading ? (
                                <tr>
                                    <td colSpan={10} className='p-4'>
                                        <ClipLoader
                                            color="#fff"
                                            loading={true}
                                            cssOverride={override}
                                            size={50}
                                        // className="spinner"
                                        />
                                    </td>
                                </tr>
                            ) : (
                                stakeStats.stakeHistory && stakeStats.stakeHistory.length > 0 ? (
                                    stakeStats.stakeHistory.map((row, index) => {
                                        index === 0 && console.log(stakeStats.userStakeReward)
                                        let earn_per = parseFloat(((parseFloat(row._totalRefClaimed / Math.pow(10,18)) + parseFloat(row._claimedReward / Math.pow(10,18))) / parseFloat(row._totalReward / Math.pow(10,18))) * 100).toFixed(2);
                                        let claimnexttime = parseFloat(row._lastclaimed) + parseFloat(commonStats.claimperiod)
                                        let withdrawavalible = parseFloat(row._totalRefClaimed / Math.pow(10,18)) + parseFloat(row._claimedReward / Math.pow(10,18)) >= parseFloat(row._totalReward / Math.pow(10,18));
                                        return (
                                            <tr key={index}>
                                                <td>${row._amountUsd ? formatPrice(row._amountUsd / Math.pow(10, accStats.tokenDecimals)) : 0}</td>
                                                <td>{row._apy ? formatPrice(row._apy / Math.pow(10, 2), 3) : 0}%</td>
                                                <td>{row._startTime ? shorterDate(row._startTime * 1000) : ' - '}</td>
                                                <td style={{color : "#7fff00"}}>{parseFloat(earn_per)}%</td>
                                                <td>{daysPassed(row._startTime)}</td>
                                                <td>{row._totalReward && row._totalReward > 0 ? formatPrice(row._totalReward / Math.pow(10,18)) : ' - '}</td>
                                                <td>{stakeStats.userStakeReward &&  stakeStats.userStakeReward[0] && stakeStats.userStakeReward[0][index] ? formatPrice(stakeStats.userStakeReward[0][index] / Math.pow(10,18)) : 0 }</td>
                                                <td>
                                                    <button onClick={() => handleClaim(index)} disabled={ earn_per < 100 && parseFloat(claimnexttime) <= Math.floor(new Date().getTime()/1000.0) ? false : true} type="button" className="theme-btn1">
                                                        {tLoading[0] === index && tLoading[1] ? 'Loading...' : 'Claim'}
                                                    </button>
                                                </td>
                                                <td>
                                                    <button onClick={() => handleTerminate(index)} disabled={ withdrawavalible ? false : true} type="button" className="theme-btn1">
                                                        {tLoading[0] === index && tLoading[1] ? 'Loading...' : 'Withdraw'}
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })

                                ) : (
                                    <tr>
                                        <td colSpan={10}>
                                            <h5 className="p-5 text-white text-center">You Have No Earn Record Yet.</h5>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className='d-flex justify-content-end mb-3'>
                        {
                            parseInt(page) > 1 &&

                            <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                setPage(page - 1);

                            }}>
                                Prev
                            </button>


                        }
                        {parseInt(page) < parseInt(stakeStats.totalPages) &&

                            <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                setPage(page + 1);

                            }}>
                                Next
                            </button>

                        }
                    </div>
                </div>
            </div>
            <MyWallet accStats={accStats} />
        </div>

    )
}
