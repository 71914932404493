import React, { useEffect, useMemo, useState } from 'react';
import logoImg from '../img/logo.png';
import { formatPrice, getWeb3 } from '../helper/functions';
import { DECIMALS, DEFAULT_CHAIN, contract, days } from '../helper/constant';
import stakeAbi from '../json/bscstake.json';
import tokenAbi from '../json/token.json';
import { useAccount, useNetwork } from 'wagmi';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { getContract } from '../helper/contractHelper';
import { toast } from 'react-toastify';
import { useWeb3Modal } from '@web3modal/react';
import { ethers } from 'ethers';
import { useLocation } from 'react-router-dom';
import Loading from './Loading';
import MyWallet from './MyWallet';
import TabButton from './TabButton';
import CustomButton from './CustomButton';
import Slider from '@mui/material/Slider';
import usdtImg from '../img/usdt.png';



export default function Stake({ commonStats, accStats, setUpdater, setTab, tab }) {
    const { address, isConnected } = useAccount()
    const { chain } = useNetwork();
    const signer = useEthersSigner()
    const { open } = useWeb3Modal()
    const [amountkey, setAmountkey] = useState(0);
    const [amount, setAmount] = useState(0);
    const [doller, setDoller] = useState(10);
    let web3 = getWeb3(DEFAULT_CHAIN);
    const [loading, setLoading] = useState(false);
    const [refAddress, setRefAddress] = useState('');
    const search = useLocation().search;
    const timenow = Math.floor(new Date().getTime() / 1000.0);
    const endtime = useMemo(() => {
        return commonStats && commonStats.stakeDays && commonStats.stakeDays[amountkey] ? timenow + parseFloat(commonStats.stakeDays[amountkey]) : 0;
    }, [amountkey, timenow, commonStats]);

    const sliderOnChange = (event, value) => {
        if (value !== 0) {
            setDoller(value);

            // Check if commonStats.stakeAmounts is defined and not empty
            if (commonStats.stakeAmounts) {
                // Find the index of the current value
                const index = commonStats.stakeAmounts.findIndex(rowdata => parseFloat(rowdata / Math.pow(10, 18)) === parseFloat(value));

                // Only set amountKey if the index is valid
                if (index !== -1) {
                    setAmountkey(index); // Use the found index
                }
            }
        }
    };
    const reward = useMemo(() => {
        return commonStats && commonStats.stakeApys && commonStats.stakeApys[amountkey] ? parseFloat(parseFloat(amount) * parseFloat(commonStats.stakeApys[amountkey]) / 10000) : 0;
    }, [amount, commonStats, amountkey]);

    useEffect(() => {
        let refAddr = '';
        const queryChainId = new URLSearchParams(search).get('ref');
        if (queryChainId !== '') {
            refAddr = queryChainId;
        }
        setRefAddress(refAddr);
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function fetch() {
            try {

                let stakeContract = new web3.eth.Contract(stakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);
                let tamount = commonStats.stakeAmounts[amountkey];
                let tokenAmount = await stakeContract.methods.getAmountsOut(tamount).call();
                setAmount(parseFloat(tokenAmount / Math.pow(10, DECIMALS)));
            }
            catch (err) {
                console.log(err.message);
            }
        }

        fetch();
        // eslint-disable-next-line
    }, [amountkey, commonStats])

    const handleApprove = async () => {
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                try {

                    setLoading(true);

                    let tokenContract = getContract(tokenAbi, contract[DEFAULT_CHAIN].USDT_ADDRESS, signer);
                    let amount1 = ethers.utils.parseEther('10000');

                    let tx = await tokenContract.approve(contract[DEFAULT_CHAIN].STAKE_ADDRESS, amount1, { 'from': address });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!');
            setLoading(false);
        }
    }

    const handleStake = async () => {
        setLoading(true);
        try {

            if (address && isConnected) {
                if (chain.id === DEFAULT_CHAIN) {

                    if (parseFloat(accStats.usdtbal) >= parseFloat(amount)) {

                        let tokenStakingAddress = contract[DEFAULT_CHAIN].STAKE_ADDRESS;
                        let stakeContract = getContract(stakeAbi, tokenStakingAddress, signer);
                        let refAddr = refAddress ? refAddress : '0x0000000000000000000000000000000000000000';

                        let tx = await stakeContract.stake(amountkey, refAddr, { 'from': address });
                        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                        toast.promise(
                            resolveAfter3Sec,
                            {
                                pending: 'Waiting for confirmation 👌',
                            }
                        )

                        var interval = setInterval(async function () {
                            let web3 = getWeb3(DEFAULT_CHAIN);
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.success('success ! your last transaction is success 👍');
                                    setUpdater(Math.random());
                                    setLoading(false);
                                }
                                else if (response.status === false) {
                                    toast.error('error ! Your last transaction is failed.');
                                    setUpdater(Math.random());
                                    setLoading(false);
                                }
                                else {
                                    toast.error('error ! something went wrong.');
                                    setUpdater(Math.random());
                                    setLoading(false);
                                }
                            }
                        }, 5000);

                    }
                    else {
                        toast.error('you don\'t have enough balance !');
                        setLoading(false);
                    }

                }
                else {
                    toast.error('Please select Binance Mainnet Network !');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason);
            setLoading(false);
        }
    }

    const marks = [
        { value: 100 },
        { value: 500 },
        { value: 1000 },
        { value: 5000 },
        { value: 10000 }
    ];

    const totalRef =
        commonStats.RefLevel && commonStats.RefRewardLevel &&
            commonStats.RefLevel.length > 0 && commonStats.RefRewardLevel[amountkey] ?
            commonStats.RefLevel.reduce((acc, level, index) => {
                // Stop summing once we reach the desired amount key
                if (parseFloat(index) >= parseFloat(commonStats.RefRewardLevel[amountkey])) return acc;
                return parseFloat(acc) + parseFloat((commonStats.RefLevel[index] / 100) || 0);
            }, 0) : 0;



    return (
        <div className='d-flex justify-content-center mb-4 row' style={{ marginLeft: 0 }}>
            <div className='col-12 col-xl-7 card'>

                <div className='d-flex justify-content-center row'>
                    <TabButton setTab={setTab} tab={tab} />
                    <div className='col-12 col-xl-10'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <h4 className='text-center mb-2'>Invest & Earn</h4>
                                {commonStats && commonStats.stakeAmounts && commonStats.stakeAmounts.length > 0 ? (
                                    commonStats.stakeAmounts.map((value, key) => {
                                        return (
                                            <button type='button' onClick={(e) => {
                                                setAmountkey(key)
                                                sliderOnChange(e, parseFloat(value / Math.pow(10, DECIMALS)))
                                            }} className={`btn ${key === amountkey ? 'btn-warning' : 'btn-warning-info bg-dark-1'} btn-amounts  my-2 mx-1 mb-md-0`}>
                                                ${formatPrice(value / Math.pow(10, DECIMALS))}
                                            </button>

                                        )
                                    })
                                ) : (
                                    <Loading />
                                )
                                }
                                <div className='input-around mt-4'>
                                    <div class="input-group" style={{ background: "#182d50" }}>

                                        <button class="btn" type="button">
                                            <img src={usdtImg} alt='bnb' height="40px" />
                                        </button>


                                        <input readOnly={true} value={amount} style={{ textAlign: "center", height: "50px", background: "#243F74", color: "#fff", }} type="text" class="form-control" placeholder="Enter amount" aria-label="Amount" aria-describedby="input-with-logo-button" />



                                        <button class="btn" type="button">
                                            <img src={logoImg} alt='bnb' height="40px" />
                                        </button>
                                    </div>
                                </div>

                                {
                                    days.map((value, key) => {
                                        return (
                                            <button type='button' className={`btn ${value === days[amountkey] ? 'btn-warning' : 'btn-warning-info bg-dark-1'} btn-amounts  my-2 mx-1 mb-md-0`}>
                                                {value} Days
                                            </button>

                                        )
                                    })
                                }

                                {/* <TextField
                                    autoComplete="off"
                                    variant="outlined"
                                    placeholder='BNB Amount'
                                    fullWidth
                                    value={amount}

                                    InputLabelProps={{
                                        style: { color: '#bbb' }, // Lighter color for label text
                                    }}
                                    InputProps={{
                                        readOnly: true, // Set input to read-only
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={logoImg} alt='bnb' height="30px" />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (

                                            <Button
                                                variant="primary"
                                                sx={{
                                                    boxSizing: 'border-box',
                                                    margin: 0,
                                                    border: 'none',
                                                    outline: 'none',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    position: 'relative',
                                                    background: 'rgb(239, 212, 141)',
                                                    fontFamily: 'inherit',
                                                    fontWeight: 700,
                                                    width: 'auto',
                                                    lineHeight: '1em',
                                                    fontSize: '15px',
                                                    borderRadius: '6px',
                                                    padding: '11px 16px',
                                                    minWidth: '50px',
                                                    color: '#000',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(239, 212, 141, 0.8)', // Optional hover effect
                                                    },
                                                }}
                                                style={{ color: '#000' }} // Inline style as fallback
                                            >
                                                {SYMBOL}
                                            </Button>

                                        ),
                                        sx: {
                                            borderRadius: '8px',
                                            backgroundColor: '#182d50',
                                            border: '0.5px solid #182d50',
                                            color: '#fff',
                                            '-webkit-text-fill-color': '#fff', // White text color
                                            textAlign: 'center', // Center the input text
                                        },
                                        inputProps: {
                                            style: { textAlign: 'center', background: "rgb(36, 63, 116)", borderRadius: "10px", margin: "10px", border: "1px solid #474747" }, // Center text in the input field
                                        },
                                    }}
                                    sx={{

                                        margin: '10px 0',
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: '#555', // Border color for default state
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#bbb', // Border color on focus
                                            },
                                        },
                                    }}
                                /> */}

                                <Slider
                                    value={doller}
                                    min={100}
                                    max={10000} // Set max to 300 based on your values
                                    step={null} // Prevent default step to allow jumping to marked values
                                    marks={marks} // Set the marks to the specific values
                                    onChange={sliderOnChange} // Handle change
                                    valueLabelDisplay="off"
                                    valueLabelFormat={(value) => `$${value}`} // Custom value display
                                    className="mb-2"
                                    sx={{
                                        color: "rgb(239, 212, 141)",
                                        height: "14px"

                                    }}
                                />

                                {/* <div className='mt-3'>
                                    <div className='d-flex justify-content-around align-items-center text-center mb-3'>

                                        <div className='bg-dark-1 px-1 py-2 px-md-3 py-md-2'>
                                            <p className='text-yellow text-small mb-0'><SavingsIcon />Earn : <span className='text-white'>{commonStats.stakeApys && commonStats.stakeApys[amountkey] ? parseFloat(commonStats.stakeApys[amountkey] / 100) : 0}%</span></p>
                                        </div>
                                        <div className='bg-dark-1 px-1 py-2 px-md-3 py-md-2'>
                                            <p className='text-yellow text-small mb-0'><EmojiEventsIcon />Reward : <span className='text-white'>{reward ? parseFloat(reward) : 0} USDT</span></p>
                                        </div>
                                        <div className='bg-dark-1 px-1 py-2 px-md-3 py-md-2'>
                                            <p className='text-yellow text-small mb-0'>
                                                <LockRoundedIcon />Level : <span className='text-white'>
                                                    {commonStats.RefRewardLevel && commonStats.RefRewardLevel.length > 0 && commonStats.RefRewardLevel[amountkey] ? commonStats.RefRewardLevel[amountkey] : 0 }
                                                </span>
                                            </p>
                                        </div>
                                        <div className='bg-dark-1 px-1 py-2 px-md-3 py-md-2'>
                                            <p className='text-yellow text-small mb-0'><LockOpenSharpIcon />Refer :
                                                <span className='text-white'>
                                                    {totalRef}%
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                </div> */}
                                <div className='mt-2'>
                                    {
                                        address && isConnected ? (
                                            parseFloat(accStats.usdtbal) >= parseFloat(amount) && parseInt(amountkey) >= parseInt(accStats.userLevel > 0 ? parseInt(accStats.userLevel) - 1 : 0)  ? (
                                                parseFloat(accStats.isApproved) >= parseFloat(amount) ? (
                                                    <CustomButton label='Invest Now' loading={loading} onClick={() => !loading ? handleStake() : null} />
                                                ) : (
                                                    <CustomButton label='Approve' loading={loading} onClick={() => !loading ? handleApprove() : null} />
                                                )
                                            ) : (
                                                <CustomButton label='Invest Now' disabled={true} />
                                            )
                                        ) : (
                                            <CustomButton label='Connect Wallet' onClick={() => open()} />
                                        )
                                    }
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <MyWallet accStats={accStats} />
        </div>
    )
}
