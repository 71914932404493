import Web3 from "web3";
import { DEFAULT_RPC } from "./constant";
import moment from "moment";


export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}


export const getWeb3 = () => {
  return new Web3(DEFAULT_RPC);
}

export const formatDateWithZone = (unixTime) => {
  try {
    let dateString = new Date(`${unixTime} UTC`).toString();
    let startIndex = dateString.indexOf("GMT");
    let modifyDate = dateString.substring(0, startIndex);
    return modifyDate;
  }
  catch (err) {
    console.log(err.message);
    return '';
  }
}

export const shorterDate = (unixTime = 0) => {
  return moment(unixTime).format("MMM Do YY");
}


export const formatDate = (unixTime, onlydate = false) => {
  try {
    if (onlydate) {
      let dateString = moment(unixTime).format("LL");
      return dateString;
    }
    else {
      let dateString = moment(unixTime).format('lll');
      return dateString;
    }
  }
  catch (err) {
    console.log(err.message);
  }

}


export const formatPrice = (num, decimals = 8) => {
  if (num >= 1) {
    return new Intl.NumberFormat('en-US', { maximumSignificantDigits: decimals }).format(num);
  }
  else {
    return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 4 }).format(num);
  }
}


export const daysPassed = (startTimestamp) => {
  const currentDate = Date.now(); // Get the current date in milliseconds
  const startDate = startTimestamp * 1000; // Convert Unix timestamp (in seconds) to milliseconds
  const timeDiff = currentDate - startDate; // Difference in milliseconds
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
  return daysDiff;
}



export const addTokenToMetaMask = async(tokenAddress, tokenSymbol, tokenDecimals, tokenImage) => {
  if (!window.ethereum) {
      console.error('MetaMask is not available.');
      return;
  }

  try {
      const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
              type: 'ERC20', // Currently only ERC-20 tokens are supported
              options: {
                  address: tokenAddress,       // The token contract address
                  symbol: tokenSymbol,         // A symbol of up to 5 characters
                  decimals: tokenDecimals,     // The number of decimals
                  image: tokenImage            // A URL to the token's logo image
              },
          },
      });

      if (wasAdded) {
          console.log(`${tokenSymbol} was added to MetaMask`);
      } else {
          console.log(`${tokenSymbol} was not added to MetaMask`);
      }
  } catch (error) {
      console.error('Error adding token:', error);
  }
}