import { useEffect, useState } from "react"
import bscstakeAbi from '../json/bscstake.json';
import tokenAbi from '../json/token.json';
import { toast } from "react-toastify";
import { DECIMALS, DEFAULT_CHAIN, SYMBOL, contract } from "../helper/constant";
import { getMultiCall } from "../helper/contractHelper";
import { useAccount } from "wagmi";
import { getWeb3 } from "../helper/functions";



export const useAccountStats = (updater, pageId = 1) => {
    let { address, isConnected } = useAccount();
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        usdtbal: 0,
        bnbBalance: 0,
        totalStaked: 0,
        totalUnStaked: 0,
        totalClaimed: 0,
        StakedCount: 0,
        tokenSymbol: SYMBOL,
        tokenDecimals: DECIMALS,
        isApproved: 0,
        stakeTokenBalance: 0,
        stakeHistory: [],
        rewardRef: 0,
        countRef: 0,
        referrer: 0,
        team: 0,
        totalValue: 0,
        userLevel : 0
    });


    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);
    let tokenContract = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].TOKEN_ADDRESS);
    let usdtContract = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].USDT_ADDRESS);


    useEffect(() => {
        const fetch = async () => {
            try {
                let stakeHistory = [];
                const bnbBalance = await web3.eth.getBalance(address).then(balance => web3.utils.fromWei(balance, 'ether'));
                const data = await getMultiCall([
                    stakeContract.methods._stakers(address), //0
                    stakeContract.methods.getLederLength(address), //1
                    stakeContract.methods.accounts(address), //2
                    stakeContract.methods.team(address), //3
                ]);

                const tokenData = await getMultiCall([
                    usdtContract.methods.allowance(address, contract[DEFAULT_CHAIN].STAKE_ADDRESS),
                    tokenContract.methods.balanceOf(address),
                    usdtContract.methods.balanceOf(address)
                ]);



                setStats({
                    usdtbal: tokenData[2] / Math.pow(10, 18),
                    bnbBalance,
                    totalStaked: data[0][1] / Math.pow(10, DECIMALS),
                    totalUnStaked: data[0][3] / Math.pow(10, DECIMALS),
                    totalClaimed: data[0][4] / Math.pow(10, DECIMALS),
                    tokenSymbol: SYMBOL,
                    tokenDecimals: DECIMALS,
                    isApproved: tokenData[0] / Math.pow(10, DECIMALS),
                    stakeTokenBalance: tokenData[1] / Math.pow(10, DECIMALS),
                    stakeHistory,
                    rewardRef: data[2][1] / Math.pow(10, DECIMALS),
                    countRef: data[2][2],
                    referrer: data[3][0],
                    totalValue: data[0][6] / Math.pow(10, DECIMALS),
                    team: data[3],
                    userLevel : data[0][5]
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (address && isConnected) {
            fetch();
        }
        else {
            setStats({
                bnbBalance: 0,
                totalAmount: 0,
                tobeRewarded: 0,
                StakedCount: 0,
                totalAmounts: 0,
                tokenSymbol: SYMBOL,
                tokenDecimals: DECIMALS,
                isApproved: 0,
                stakeTokenBalance: 0,
                stakeHistory: [],
                rewardRef: 0,
                countRef: 0,
                referrer: 0,
                team: 0,
                totalValue: 0,
                userLevel : 0
            })
        }
        // eslint-disable-next-line
    }, [updater, address, pageId]);

    return stats;
}



export const useDirectReferralStats = (pageId = 1, level = 1) => {
    let { address, isConnected } = useAccount();
    let web3 = getWeb3();



    const [stats, setStats] = useState({
        data: [],
        totalLength: [],
        totalPages: 0,
        page: 1,
        levelcount: [],
        loading: true
    });


    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {
                setStats({
                    ...stats,
                    loading: true
                })

                const totalLength = await getMultiCall([
                    stakeContract.methods.referralDirectLength(address, 0), //0
                    stakeContract.methods.referralDirectLength(address, 1), //1
                    stakeContract.methods.referralDirectLength(address, 2), //2
                    stakeContract.methods.referralDirectLength(address, 3), //3
                    stakeContract.methods.referralDirectLength(address, 4), //3
                    stakeContract.methods.referralDirectLength(address, 5), //3
                    stakeContract.methods.referralDirectLength(address, 6), //3
                    stakeContract.methods.referralDirectLength(address, 7), //3
                    stakeContract.methods.referralDirectLength(address, 8) //3
                ]);



                // Example usage:
                var totalRecords = totalLength[level - 1];
                var recordsPerPage = 3;
                var totalPages = Math.ceil(totalRecords / recordsPerPage);
                let data = [];
                if (totalRecords > 0) {
                    var paginationInfo = calculatePagination(totalRecords, recordsPerPage, pageId);
                    data = await getMultiCall([
                        stakeContract.methods.userDirectReferral(address, level - 1, paginationInfo.startRecord, paginationInfo.endRecord) //0
                    ]);
                }

                setStats({
                    data: data[0],
                    page: pageId,
                    totalLength: totalRecords,
                    levelcount: totalLength,
                    totalPages,
                    loading: false
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (address && isConnected) {
            fetch();
        }
        else {
            setStats({
                data: [],
                totalLength: 0,
                totalPages: 0,
                page: 1,
                levelcount: [],
                loading: false
            })
        }
        // eslint-disable-next-line
    }, [pageId, address, level]);

    return stats;
}


export const useStakeStats = (updater, pageId = 1) => {
    let { address, isConnected } = useAccount();
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        stakeHistory: [],
        totalPages: 0,
        totalLength: 0,
        page: 1,
        loading: true,
        start: 0,
        end: 0,
        userStakeReward : []
    });


    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {
                setStats({
                    ...stats,
                    loading: true
                })

                let stakeHistory = [];
                const data = await getMultiCall([
                    stakeContract.methods.getLederLength(address), //0
                ]);

                var totalRecords = data[0];
                var recordsPerPage = 8;
                var totalPages = Math.ceil(totalRecords / recordsPerPage);
                var paginationInfo = calculatePagination(totalRecords, recordsPerPage, pageId);
                if (totalRecords > 0) {

                    stakeHistory = await getMultiCall([
                        stakeContract.methods.userStakeHistory(address, paginationInfo.startRecord, paginationInfo.endRecord), //0
                        stakeContract.methods.userStakeReward(address, paginationInfo.startRecord, paginationInfo.endRecord) //1
                    ]);

                    console.log(stakeHistory[1])
                }

                



                setStats({
                    stakeHistory: stakeHistory[0],
                    page: pageId,
                    totalLength: totalRecords,
                    totalPages,
                    loading: false,
                    start: paginationInfo.startRecord ? paginationInfo.startRecord : 0,
                    end: paginationInfo.endRecord ? paginationInfo.endRecord : 0,
                    userStakeReward : stakeHistory[1]
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (address && isConnected) {
            fetch();
        }
        else {
            setStats({
                stakeHistory: [],
                totalPages: 0,
                totalLength: 0,
                page: 1,
                loading: false,
                start: 0,
                end: 0,
                userStakeReward : []
            })
        }
        // eslint-disable-next-line
    }, [updater, address, pageId]);

    return stats;
}


function calculatePagination(totalRecords, recordsPerPage, pageNumber) {
    // Calculate total number of pages
    var totalPages = Math.ceil(totalRecords / recordsPerPage);

    // Adjust page number if out of range
    pageNumber = Math.min(Math.max(pageNumber, 1), totalPages);

    // Calculate start and end record numbers for pagination
    var startRecord = Math.max((pageNumber - 1) * recordsPerPage, 0);
    var endRecord = Math.min(startRecord + recordsPerPage - 1, totalRecords - 1);

    // If startRecord is 0 and endRecord is still 0 (only for the first page), adjust endRecord to 1
    if (startRecord === 0 && endRecord === 0) {
        endRecord = 1;
    }

    return {
        startRecord: startRecord,
        endRecord: endRecord
    };
}



export const useAdminStats = (updater) => {
    let { address, isConnected } = useAccount();
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        owner: '',
        pauser: false,
        wpauser: false,
        default_referral: '',
        ref_per: [],
        stake_amounts: [],
        dividend: [],
        perday: [],
        tokenPrice : 0,
        claimtime : 0

    });


    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await getMultiCall([
                    stakeContract.methods.owner(), //0
                    stakeContract.methods.paused(), //1
                    stakeContract.methods.wpaused(), //2
                    stakeContract.methods.default_referral(), //3
                    stakeContract.methods.getContractInfo(), //4
                    stakeContract.methods.claimperiod(), //5
                ]);

                const stakeAounts = data[4][0].map(num => num / Math.pow(10, 18));
                const dividend = data[4][1].map(num => num / Math.pow(10, 18));
                const reflevel = data[4][2].map(num => num / 100);
                const perday = data[4][4].map(num => num / 100);
                
            
                setStats({
                    owner: data[0],
                    pauser: data[1],
                    wpauser: data[2],
                    default_referral: data[3],
                    ref_per: reflevel,
                    stake_amounts:  stakeAounts,
                    dividend : dividend,
                    perday,
                    tokenPrice : data[4][3] / Math.pow(10,8),
                    claimtime : data[5]

                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (address && isConnected) {
            fetch();
        }
        else {
            setStats({
                owner: '',
                pauser: false,
                wpauser: false,
                default_referral: '',
                ref_per: [],
                stake_amounts: [],
                dividend: [],
                perday: [],
                tokenPrice : 0,
                claimtime : 0
            })
        }
        // eslint-disable-next-line
    }, [updater, address]);

    return stats;
}


export const useStakeHistoryStats = (updater, pageId = 1) => {
    let { address, isConnected } = useAccount();
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        stakeHistory: [],
        totalPages: 0,
        totalLength: 0,
        page: 1,
        loading: true,
        start: 0,
        end: 0
    });


    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {
                setStats({
                    ...stats,
                    loading: true
                })

                let stakeHistory = [];
                const data = await getMultiCall([
                    stakeContract.methods._stakers(address), //0
                ]);

                var totalRecords = data[0][6];
                var recordsPerPage = 8;
                var totalPages = Math.ceil(totalRecords / recordsPerPage);
                var paginationInfo = calculatePagination(totalRecords, recordsPerPage, pageId);
                if (totalRecords > 0) {

                    stakeHistory = await getMultiCall([
                        stakeContract.methods.userAllStakeHistory(address, paginationInfo.startRecord, paginationInfo.endRecord), //0
                    ]);
                }


                setStats({
                    stakeHistory: stakeHistory[0],
                    page: pageId,
                    totalLength: totalRecords,
                    totalPages,
                    loading: false,
                    start: paginationInfo.startRecord ? paginationInfo.startRecord : 0,
                    end: paginationInfo.endRecord ? paginationInfo.endRecord : 0,
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (address && isConnected) {
            fetch();
        }
        else {
            setStats({
                stakeHistory: [],
                totalPages: 0,
                totalLength: 0,
                page: 1,
                loading: false,
                start: 0,
                end: 0
            })
        }
        // eslint-disable-next-line
    }, [updater, address, pageId]);

    return stats;
}
