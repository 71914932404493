import React from 'react'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Groups2Icon from '@mui/icons-material/Groups2';
import { addTokenToMetaMask, formatPrice } from '../helper/functions';
import { contract, DEFAULT_CHAIN, SYMBOL , USDT_IMG , WC_IMG } from '../helper/constant';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import swapIcon from '../img/plus.png';


export default function MyWallet({ accStats }) {
    return (
        <div className='col-sm-12 col-md-12 col-xl-4 mt-4 mt-xl-0'>
            <div className='card wallet-card'>

                <div className='card-body'>
                    <h5 className='mb-0 p-2'>My Wallet</h5>
                    <div className='card card-gray mb-2 back1-top'>
                        <div className='card-body'>

                            <div className='d-flex align-items-center'>
                                <div className='icon-box'>
                                    <SwapHorizIcon sx={{ color: "#fff" }} fontSize="large" />
                                </div>
                                <div className='ms-3'>
                                    <h6 className='text-black mb-1'>USDT Balance</h6>
                                    <h5 className='mb-0 text-black'>{accStats.usdtbal ? formatPrice(accStats.usdtbal, 5) : 0} USDT</h5>
                                </div>
                                <div className='ms-auto' style={{ cursor: "pointer" }} onClick={() => addTokenToMetaMask(contract[DEFAULT_CHAIN].USDT_ADDRESS, 'USDT', 18, USDT_IMG)}>
                                    <img src={swapIcon} alt='swap' height="24px" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='card card-gray mb-2 back2-top'>
                        <div className='card-body'>
                            <div className='d-flex align-items-center'>
                                <div className='icon-box'>
                                    <AccountBalanceWalletIcon sx={{ color: "#fff" }} fontSize="large" />

                                </div>
                                <div className='ms-3'>
                                    <h6 className='text-black mb-1'>{SYMBOL} Balance</h6>
                                    <h5 className='mb-0 text-black'>{accStats.stakeTokenBalance ? formatPrice(accStats.stakeTokenBalance, 15) : 0} {SYMBOL}</h5>
                                </div>
                                <div className='ms-auto' style={{ cursor: "pointer" }} onClick={() => addTokenToMetaMask(contract[DEFAULT_CHAIN].TOKEN_ADDRESS, 'WC', 18, WC_IMG)}>
                                    <img src={swapIcon} alt='swap' height="24px" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card card-gray mb-2 back3-top'>
                        <div className='card-body'>
                            <div className='d-flex'>
                                <div className='icon-box'>
                                    <EmojiEventsIcon sx={{ color: "#fff" }} fontSize="large" />
                                </div>
                                <div className='ms-3'>
                                    <h6 className='text-black mb-1'>Your Level</h6>
                                    <h5 className='mb-0'>
                                        {
                                            Array.from({ length: 5 }).map((_, index) => {
                                                if (parseInt(index) < parseInt(accStats.userLevel)) {
                                                    return <StarIcon key={index} sx={{ color: "#000" }} fontSize="large" />;
                                                } else {
                                                    return <StarBorderIcon key={index} sx={{ color: "#000" }} fontSize="large" />;
                                                }
                                            })
                                        }
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card card-gray mb-2 back1-top'>
                        <div className='card-body'>
                            <div className='d-flex'>
                                <div className='icon-box'>
                                    <LightbulbIcon sx={{ color: "#fff" }} fontSize="large" />
                                </div>
                                <div className='ms-3'>
                                    <h6 className='text-black mb-1'>Referral Earnings</h6>
                                    <h5 className='mb-0 text-black'>{accStats.rewardRef ? formatPrice(accStats.rewardRef, 10) : 0} USDT</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card card-gray mb-2 back2-top'>
                        <div className='card-body'>
                            <div className='d-flex'>
                                <div className='icon-box'>
                                    <Groups2Icon sx={{ color: "#fff" }} fontSize="large" />
                                </div>
                                <div className='ms-3'>
                                    <h6 className='text-black mb-1'>Your Team Level</h6>
                                    <h5 className='mb-0 text-black'>{accStats.team ? formatPrice(accStats.team, 10) : 0} </h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
