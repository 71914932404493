import { bsc } from "wagmi/chains";


export const contract = {
  97: {
    STAKE_ADDRESS: "0x9f255b639de7640587e276e89760e5c614ffd03f",
    MULTICALL_ADDRESS: "0xa54fE4a3DbD1Eb21524Cd73754666b7E13b4cB18",
    TOKEN_ADDRESS: "0x013147eab8148937dd959c8bf3759b9ab54373f3",
    USDT_ADDRESS : "0xc7b6278c411564729c02548a5742e6d5788bac33"
  },
  56: {
    STAKE_ADDRESS: "0xb02922afc7aa9f37059475671bf53f4bb0a06547",
    MULTICALL_ADDRESS: "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116",
    TOKEN_ADDRESS: "0x1FAB731bcb7C0893AC863165D48DE625c67604F2",
    USDT_ADDRESS : "0x55d398326f99059fF775485246999027B3197955"
  }
}

export const DEFAULT_CHAIN = 56;
export const DEFAULT_RPC = "https://bsc-dataseed1.binance.org/"
// export const DEFAULT_RPC = "https://data-seed-prebsc-2-s1.binance.org:8545/"
export const PROJECT_ID = "27b9720018bf7f8740936e6a6eb28604";
export const DECIMALS = 18;
export const SYMBOL = 'WE';
export const EXPLOER = 'https://bscscan.com/';
export const chains = [bsc]
export const days = [210,240,270,275,285];
export const USDT_IMG = "https://tokens.pancakeswap.finance/images/symbol/usdt.png"
export const WC_IMG = "https://alchemy.mypinata.cloud/ipfs/QmdWpGcCgKMTfj7u1biALjhWk2t7KXaZHC5Nw9ehV7VrAM"






