import React, { useEffect, useState } from 'react'
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useDirectReferralStats } from '../hooks/useBscAccount';
import { ClipLoader } from 'react-spinners';
import { DECIMALS, EXPLOER, SYMBOL } from '../helper/constant';
import { formatPrice, shorterDate, trimAddress } from '../helper/functions';
// import ContentPasteIcon from '@mui/icons-material/ContentPaste';
// import CopyToClipboard from 'react-copy-to-clipboard';

export default function ReferralHistory() {
    const [page, setPage] = useState(1)
    const [level, setLevel] = useState(1)
    const stats = useDirectReferralStats(page, level);
    // const [copied, setCopied] = useState(false);

    const override = {
        display: "block",
        marginRight: "auto",
        marginLeft: "auto"
    };

    useEffect(() => {
        setPage(1)
    }, [level])

    return (
        <div className='row mt-4 mb-5 d-flex justify-content-center ref-history'>
            <div className='col-12 col-xl-11 col-lg-11'>
                <div>
                    <div className='card-header'>
                        <div className='d-md-flex justify-content-between'>
                            <h3 className='mb-2 mb-md-0'>Referral History</h3>
                            <div>
                                <button onClick={() => setLevel(1)} className={`btn btn-outline-warning ${level === 1 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.1({stats && stats.levelcount && stats.levelcount[0] ? stats.levelcount[0] : 0})</button>
                                <button onClick={() => setLevel(2)} className={`btn btn-outline-warning ${level === 2 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.2({stats && stats.levelcount && stats.levelcount[1] ? stats.levelcount[1] : 0})</button>
                                <button onClick={() => setLevel(3)} className={`btn btn-outline-warning ${level === 3 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.3({stats && stats.levelcount && stats.levelcount[2] ? stats.levelcount[2] : 0})</button>
                                <button onClick={() => setLevel(4)} className={`btn btn-outline-warning ${level === 4 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.4({stats && stats.levelcount && stats.levelcount[3] ? stats.levelcount[3] : 0})</button>
                                <button onClick={() => setLevel(5)} className={`btn btn-outline-warning ${level === 5 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.5({stats && stats.levelcount && stats.levelcount[4] ? stats.levelcount[4] : 0})</button>
                                <button onClick={() => setLevel(6)} className={`btn btn-outline-warning ${level === 6 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.6({stats && stats.levelcount && stats.levelcount[5] ? stats.levelcount[5] : 0})</button>
                                <button onClick={() => setLevel(7)} className={`btn btn-outline-warning ${level === 7 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.7({stats && stats.levelcount && stats.levelcount[6] ? stats.levelcount[6] : 0})</button>
                                <button onClick={() => setLevel(8)} className={`btn btn-outline-warning ${level === 8 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.8({stats && stats.levelcount && stats.levelcount[7] ? stats.levelcount[7] : 0})</button>
                                <button onClick={() => setLevel(9)} className={`btn btn-outline-warning ${level === 9 && 'bg-warning btn-ref-tab'} mx-1 mb-2 mb-md-0`}>LV.9({stats && stats.levelcount && stats.levelcount[8] ? stats.levelcount[8] : 0})</button>
                            </div>
                        </div>
                    </div>
                    <div className='table-responsive'>
                        <table class="table table-bordered border-primary">
                            <thead>
                                <tr>
                                    <th scope="col">Invest</th>
                                    <th scope="col">Start</th>
                                    <th scope="col">APY</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stats.loading ? (
                                    <tr>
                                        <td colSpan={6} className='p-4'>
                                            <ClipLoader
                                                color="#fff"
                                                loading={true}
                                                cssOverride={override}
                                                size={50}
                                            // className="spinner"
                                            />
                                        </td>
                                    </tr>
                                ) :

                                    stats.data && stats.data.length > 0 ? (stats.data.map((items, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    {items.depositAmount ? formatPrice(items.depositAmount / Math.pow(10,18)) : 0 }
                                                </td>
                                                <td>
                                                    {shorterDate(items.startTime * 1000)}
                                                </td>
                                                <td>
                                                    {items.apy / 100}%
                                                </td>
                                                <td>
                                                    {/* <CopyToClipboard text={items.from}
                                                        onCopy={() => {
                                                            setCopied(true)
                                                            setTimeout(() => {
                                                                setCopied(false)
                                                            }, 2000)
                                                        }}>
                                                        {copied ? (
                                                            <ContentPasteIcon sx={{ color: "#fff" }} fontSize="small" />
                                                        ) : (
                                                            <ContentCopyIcon sx={{ color: "#fff" }} fontSize="small" />

                                                        )}
                                                    </CopyToClipboard> */}
                                                    <a href={`${EXPLOER}/address/${items.from}`} target='_blank' rel="noreferrer">
                                                        <span className='mx-1'>{trimAddress(items.from, 3)}</span>
                                                    </a>
                                                </td>
                                                <td>
                                                    {formatPrice(items.referralAmount / Math.pow(10, DECIMALS))} USDT
                                                </td>
                                            </tr>
                                        )
                                    })) : (
                                        <tr>
                                            <td colSpan={6} className='text-center'>No Record Found</td>
                                        </tr>
                                    )
                                }
                            </tbody>

                        </table>
                        <div className='d-flex justify-content-end mb-3'>
                            {
                                parseInt(page) > 1 &&

                                <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                    setPage(page - 1);

                                }}>
                                    Prev
                                </button>


                            }
                            {parseInt(page) < parseInt(stats.totalPages) &&

                                <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                    setPage(page + 1);

                                }}>
                                    Next
                                </button>

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
