import React, { useState } from 'react'
import { daysPassed, formatPrice, shorterDate } from '../helper/functions';
import TabButton from './TabButton';
import MyWallet from './MyWallet';
import { useStakeHistoryStats } from '../hooks/useBscAccount';
import { ClipLoader } from 'react-spinners';


export default function History({ accStats, updater, setTab, tab }) {
    const [page, setPage] = useState(1)
    const stakeStats = useStakeHistoryStats(updater, page)

    const override = {
        display: "block",
        marginRight: "auto",
        marginLeft: "auto"
    };

    return (
        <div className='d-flex justify-content-center mb-4 row' style={{ marginLeft: 0 }}>
            <div className='col-12 col-xl-7 card'>
                <TabButton setTab={setTab} tab={tab} />
                <div className='table-responsive'>
                    <table class="table table-bordered border-primary table-unstake">
                        <thead>
                            <tr>
                                <th scope="col">Plan($)</th>
                                <th scope="col">APY</th>
                                <th scope="col">Start</th>
                                <th scope="col">Earn(%)</th>
                                <th scope="col">Time(D)</th>
                                <th scope="col">Earn(USDT)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stakeStats.loading ? (
                                <tr>
                                    <td colSpan={10} className='p-4'>
                                        <ClipLoader
                                            color="#fff"
                                            loading={true}
                                            cssOverride={override}
                                            size={50}
                                        // className="spinner"
                                        />
                                    </td>
                                </tr>
                            ) : (
                                stakeStats.stakeHistory && stakeStats.stakeHistory.length > 0 ? (
                                    stakeStats.stakeHistory.map((row, index) => {
                                        let earn_per = parseFloat(((parseFloat(row._totalRefClaimed / Math.pow(10,18)) + parseFloat(row._claimedReward / Math.pow(10,18))) / parseFloat(row._totalReward / Math.pow(10,18))) * 100).toFixed(2);
                                        
                                        return (
                                            <tr key={index}>
                                                <td>${row._amountUsd ? formatPrice(row._amountUsd / Math.pow(10, accStats.tokenDecimals)) : 0}</td>
                                                <td>{row._apy ? formatPrice(row._apy / Math.pow(10, 2), 3) : 0}%</td>
                                                <td>{row._startTime ? shorterDate(row._startTime * 1000) : ' - '}</td>
                                                <td style={{color : "#7fff00"}}>{parseFloat(earn_per)}%</td>
                                                <td>{daysPassed(row._startTime)}</td>
                                                <td>{row._totalReward && row._totalReward > 0 ? formatPrice(row._totalReward / Math.pow(10,18)) : ' - '}</td>
                                            </tr>
                                        )
                                    })

                                ) : (
                                    <tr>
                                        <td colSpan={10}>
                                            <h5 className="p-5 text-white text-center">You Have No Earn Record Yet.</h5>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className='d-flex justify-content-end mb-3'>
                        {
                            parseInt(page) > 1 &&

                            <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                setPage(page - 1);

                            }}>
                                Prev
                            </button>


                        }
                        {parseInt(page) < parseInt(stakeStats.totalPages) &&

                            <button className='btn btn-sm btn-outline-light mx-2' onClick={() => {
                                setPage(page + 1);

                            }}>
                                Next
                            </button>

                        }
                    </div>
                </div>
            </div>
            <MyWallet accStats={accStats} />
        </div>

    )
}
