import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import Admin from './pages/Admin';
// import Admin from './pages/Admin';

function App() {
  return (
    <>
      <BrowserRouter basename="/invest">
        <ToastContainer pauseOnFocusLoss={false} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/admin" element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
