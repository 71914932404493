import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';


export default function CustomButton({
    label = 'Connect Wallet',
    onClick = () => { },
    icon = null,
    background = 'rgb(239, 212, 141)',
    textColor = '#000',
    width = '100%',
    fontSize = '14px',
    padding = '13px 44px',
    margin = "0px",
    borderRadius = '10px',
    minWidth = '120px',
    loading = false,
    disabled = false,
    className = ''
}) {
    return (
        <Button
            className={className}
            sx={{
                boxSizing: 'border-box',
                margin,
                border: 'none',
                outline: 'none',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                position: 'relative',
                fontFamily: 'inherit',
                fontWeight: 700,
                width: width,
                lineHeight: '1em',
                fontSize: fontSize,
                borderRadius: borderRadius,
                padding: padding,
                minWidth: minWidth,
                color: textColor,
                background: background,
                transition: 'background-color 100ms',
                '&:hover': { // Custom disabled styles
                    color: '#fff', // Text color stays white
                    background : "rgb(239, 212, 141)"
                },
                '&:disabled': { // Custom disabled styles
                    color: '#fff', // Text color stays white
                    border : "1px solid rgb(239, 212, 141)",
                },
            }}
            variant="contained"
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : (icon ? icon : null)} // Show loader if loading
            onClick={onClick}
            disabled={loading || disabled}
        >
            {loading ? '' : label}
        </Button>
    );
}