import React, { useEffect, useState } from 'react'
import ConnectButton from '../components/ConnectButton'
import { useAdminStats } from '../hooks/useBscAccount'
import { useAccount, useNetwork } from 'wagmi'
import stakeABi from '../json/bscstake.json';
import { DEFAULT_CHAIN, contract } from '../helper/constant';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { toast } from 'react-toastify';
import { getContract } from '../helper/contractHelper';
import { getWeb3 } from '../helper/functions';
import { ethers } from 'ethers';

export default function Admin() {
    const { address } = useAccount();
    const { chain } = useNetwork();
    const signer = useEthersSigner();
    const [inputs, setInputs] = useState({
        withdraw_token_address: "",
        withdraw_token_amount: "",
        withdraw_token_decimals: "",
        new_owner: "",
        ref_per: Array(9).fill(""), // 9 values
        deposit_pauser: false,
        withdraw_pauser: false,
        default_referral: "",
        tokenPrice: 0,
        stake_amounts: Array(5).fill(""), // 5 values
        staketime: Array(5).fill(""),// 5 values
        dividend: Array(5).fill(""), // 5 values
        claimtime: 0
    });
    const [updater, setUpdater] = useState(1);
    const stats = useAdminStats(updater);

    useEffect(() => {
        setInputs({
            ...inputs,
            new_owner: stats.owner || "",
            deposit_pauser: stats.pauser ,
            withdraw_pauser: stats.wpauser,
            default_referral: stats.default_referral || "",
            stake_amounts: stats.stake_amounts || Array(5).fill(""),
            ref_per: stats.ref_per || Array(9).fill(""),
            staketime: stats.perday || Array(5).fill(""),
            dividend: stats.dividend || Array(5).fill(""),
            tokenPrice: stats.tokenPrice,
            claimtime: stats.claimtime
        });
    }, [stats]);

    // Handle individual array element change for ref_per and stake_amounts
    const handleArrayChange = (index, e, arrayName) => {
        const updatedArray = [...inputs[arrayName]];
        updatedArray[index] = e.target.value; // Update specific index
        setInputs({
            ...inputs,
            [arrayName]: updatedArray
        });
    };

    const handleChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        });
    };

    const handleSelectChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value === "true"
        });
    };

    const handleSubmit = async (id = 0) => {
        if (address && id) {
            if (chain && chain.id === DEFAULT_CHAIN) {
                try {
                    let tokenContract = getContract(stakeABi, contract[DEFAULT_CHAIN].STAKE_ADDRESS, signer);
                    let tx;
                    if (id === 1) {
                        tx = await tokenContract.rescueFunds({ 'from': address });
                    }
                    else if (id === 2) {
                        let a = ethers.utils.parseUnits(inputs.withdraw_token_amount.toString(), inputs.withdraw_token_decimals);
                        tx = await tokenContract.rescueToken(inputs.withdraw_token_address, a, { 'from': address });
                    }
                    else if (id === 3) {
                        tx = await tokenContract.transferOwnership(inputs.new_owner, { 'from': address });
                    }
                    else if (id === 4) {
                        let a = inputs.ref_per.map((items) => items * 100);
                        tx = await tokenContract.setReferralLevel(a, { 'from': address });
                    }
                    else if (id === 5) {
                        tx = await tokenContract.setPauser(inputs.deposit_pauser, { 'from': address });
                    }
                    else if (id === 6) {
                        tx = await tokenContract.setWPauser(inputs.withdraw_pauser, { 'from': address });
                    }
                    else if (id === 7) {
                        tx = await tokenContract.setDefaultReferral(inputs.default_referral, { 'from': address });
                    }
                    else if (id === 8) {
                        let a = inputs.stake_amounts.map((items) => ethers.utils.parseEther(items.toString()));
                        tx = await tokenContract.setStakeAmounts(a, { 'from': address });
                    }
                    else if (id === 9) {
                        let a = inputs.staketime.map((items) => items * 100);
                        tx = await tokenContract.setStakeTime(a, { 'from': address });
                    }
                    else if (id === 10) {
                        let a = inputs.dividend.map((items) => ethers.utils.parseEther(items.toString()));
                        tx = await tokenContract.setDividend(a, { 'from': address });
                    }
                    else if (id === 11) {
                        let a = ethers.utils.parseUnits(inputs.tokenPrice.toString(), 8);
                        tx = await tokenContract.setTokenPrice(a, { 'from': address });
                    }
                    else if (id === 12) {
                        tx = await tokenContract.setClaimperiod(inputs.claimtime, { 'from': address });
                    }
                    else {
                        return false;
                    }



                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');

                                setUpdater(Math.random());
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');

                                setUpdater(Math.random());
                            }
                            else {
                                toast.error('error ! something went wrong.');

                                setUpdater(Math.random());
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.error(err.reason ? err.reason : err.message);

                }
            }
            else {
                toast.error('Please select Binance Mainnet Network !');

            }

        }
        else {
            toast.error('Please Connect Wallet!');

        }
    }


    return (
        <>
            <div className='d-flex justify-content-between m-5'>
                <h5>Admin Settings</h5>
                <ConnectButton />
            </div>
            <div className='mt-5'>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Input</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>rescueFunds</td>
                            <td></td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(1)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>rescueToken</td>
                            <td>
                                <input value={inputs.withdraw_token_address} onChange={(e) => handleChange(e)} placeholder='Enter token address' type='text' name='withdraw_token_address' className='form-control' />
                                <input value={inputs.withdraw_token_amount} onChange={(e) => handleChange(e)} placeholder='Enter token amount' type='text' name='withdraw_token_amount' className='form-control' />
                                <input value={inputs.withdraw_token_decimals} onChange={(e) => handleChange(e)} placeholder='Enter token decimals' type='text' name='withdraw_token_decimals' className='form-control' />
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(2)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Transfer Ownership</td>
                            <td>
                                <input value={inputs.new_owner} onChange={(e) => handleChange(e)} placeholder='Enter new  owner address' type='text' name='new_owner' className='form-control' />
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(3)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Referral Level Percentage</td>
                            <td>
                                {/* Generate 9 input fields for ref_per */}
                                {inputs.ref_per.map((ref, index) => (
                                    <input
                                        key={index}
                                        value={ref}
                                        onChange={(e) => handleArrayChange(index, e, 'ref_per')}
                                        placeholder={`Enter level ${index + 1} percentage`}
                                        type='number'
                                        className='form-control mb-2'
                                    />
                                ))}
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(4)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Deposit Pauser</td>
                            <td>
                                <select value={inputs.deposit_pauser} onChange={(e) => handleSelectChange(e)} name='deposit_pauser' className="form-select" aria-label="Default select example">
                                    <option value={true} selected={ inputs.deposit_pauser === true}>True</option>
                                    <option value={false} selected={ inputs.deposit_pauser === false}>False</option>
                                </select>
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(5)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>Withdraw Pauser</td>
                            <td>
                                <select value={inputs.withdraw_pauser} onChange={(e) => handleSelectChange(e)} name="withdraw_pauser" className="form-select" aria-label="Default select example">
                                    <option value={true}>True</option>
                                    <option value={false}>False</option>
                                </select>
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(6)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">7</th>
                            <td> Default Referral</td>
                            <td>
                                <input value={inputs.default_referral} onChange={(e) => handleChange(e)} placeholder='Enter Default Referral Address' type='text' name='default_referral' className='form-control' />
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(7)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">8</th>
                            <td>Stake Amounts</td>
                            <td>
                                {/* Generate 5 input fields for stake_amounts */}
                                {inputs.stake_amounts.map((amount, index) => (
                                    <input
                                        key={index}
                                        value={amount}
                                        onChange={(e) => handleArrayChange(index, e, 'stake_amounts')}
                                        placeholder={`Enter stake amount ${index + 1}`}
                                        type='number'
                                        className='form-control mb-2'
                                    />
                                ))}
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(8)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">9</th>
                            <td>Reward Amount</td>
                            <td>
                                {/* Generate 5 input fields for stake_amounts */}
                                {inputs.dividend.map((time, index) => (
                                    <input
                                        key={index}
                                        value={time}
                                        onChange={(e) => handleArrayChange(index, e, 'dividend')}
                                        placeholder={`Enter reward amount ${index + 1}`}
                                        type='number'
                                        className='form-control mb-2'
                                    />
                                ))}
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(10)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">10</th>
                            <td>Per Day Reward Percentage(%)</td>
                            <td>
                                {/* Generate 5 input fields for stake_amounts */}
                                {inputs.staketime.map((time, index) => (
                                    <input
                                        key={index}
                                        value={time}
                                        onChange={(e) => handleArrayChange(index, e, 'staketime')}
                                        placeholder={`Enter stake time ${index + 1}`}
                                        type='number'
                                        className='form-control mb-2'
                                    />
                                ))}
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(9)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">11</th>
                            <td>Token Price($)</td>
                            <td>
                                <input value={inputs.tokenPrice} onChange={(e) => handleChange(e)} placeholder='Enter token price' type='text' name='tokenPrice' className='form-control' />
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(11)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">12</th>
                            <td>Claim Time(seconds)</td>
                            <td>
                                <input value={inputs.claimtime} onChange={(e) => handleChange(e)} placeholder='Enter token price' type='text' name='claimtime' className='form-control' />
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(12)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    );
}
